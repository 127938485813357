// 整数验证
export function integerValidate(value, maxLen = 10) {
  value = String(value);
  value = value.replace(/[^\d]/g, "");
  value = value.replace(/^0{2,}/g, "0");
  if (value.length > maxLen) {
    value = value.substring(0, maxLen);
  }
  return String(value);
}
// 正整数验证
export function positiveIntegerValidate(value, maxLen = 10) {
  value = String(value);
  value = value.replace(/[^\d]/g, "");
  value = value.replace(/^0/g, "");
  if (value.length > maxLen) {
    value = value.substring(0, maxLen);
  }
  return String(value);
}

// 金额验证
export function floatNumberValidate(value) {
  value = String(value);
  // 先把非数字的都替换掉，除了数字和.
  value = value.replace(/[^\d.]/g, "");
  // 必须保证第一个为数字而不是.
  value = value.replace(/^\./g, "");
  // 当第一个是0时不能重复输入0
  value = value.replace(/^0{2,}/g, "0");
  // 保证.只出现一次，而不能出现两次以上
  value = value
    .replace(".", "$#$")
    .replace(/\./g, "")
    .replace("$#$", ".");
  let index = -1;
  for (const i in value) {
    if (value[i] === ".") {
      index = i;
    }
    if (index !== -1) {
      if (i - index > 2) {
        value = value.substring(0, value.length - 1);
      }
    }
  }
  // 数字文本框，最多10位，最多两位小数
  if (Number(value) >= 10000000000) {
    value = "9999999999.99";
  }
  return value;
}
export function zeroValidate(value) {
  if (Number(value) > 0) {
    return value;
  } else {
    return "";
  }
}

// 折扣验证
export function discountValidate(value) {
  value = String(value);
  // 先把非数字的都替换掉，除了数字和.
  value = value.replace(/[^\d.]/g, "");
  // 必须保证第一个为数字而不是.
  value = value.replace(/^\./g, "");
  // value = value.replace(/^0/g, "");
  // 当第一个是0时不能重复输入0
  // value = value.replace(/^0{2,}/g, "0");
  value = value.replace(/^0+[^.]/g, "0");
  // 保证.只出现一次，而不能出现两次以上
  value = value
    .replace(".", "$#$")
    .replace(/\./g, "")
    .replace("$#$", ".");
  let index = -1;
  for (const i in value) {
    if (value[i] === ".") {
      index = i;
    }
    if (index !== -1) {
      if (i - index > 1) {
        value = value.substring(0, value.length - 1);
      }
    }
  }
  // 数字文本框，最多10位，最多两位小数
  if (Number(value) >= 10) {
    value = "9.9";
  }
  return value;
}

export const mobileValidate = (rule, value, callback) => {
  const reg = /(^0[1-9]\d{1,2}-?\d{7,8}$)|(\d{7,8})|(^1[3-9]\d{9}$)/
  if (!value || !value.length) {
    callback(new Error('手机号码输入不合法'))
    return
  }
  if (value === '') {
    callback(new Error('手机号码输入不合法'))
  } else if (value.length !== 11 || !reg.test(value)) {
    callback(new Error('手机号码输入不合法'))
  } else {
    callback()
  }
}

export const mobileValidateNotRequired = (rule, value, callback) => {
  const reg = /(^0[1-9]\d{1,2}-?\d{7,8}$)|(\d{7,8})|(^1[3-9]\d{9}$)/
  if (!value || !value.length) {
    callback()
    return
  }
  if (value === '') {
    callback()
  } else if (value.length !== 11 || !reg.test(value)) {
    callback(new Error('手机号码输入不合法'))
  } else {
    callback()
  }
}

// 账户密码
export const passwordValidate = (rule, value, callback) => {
  const reg = /^[0-9a-zA-Z]+$/
  if (value === '') {
    callback(new Error('请输入密码/密码不能为空'))
  } else if (value.length < 8) {
    callback(new Error('请输入8-16位的字母或数字'))
  } else if (!reg.test(value)) {
    callback(new Error('请输入8-16位的字母或数字'))
  } else {
    callback()
  }
}

// 短信验证码
export const captchaValidate = (rule, value, callback) => {
  const reg = /^[0-9]+$/
  if (value === '') {
    callback(new Error('请输入验证码'))
  } else if (value.length < 6) {
    callback(new Error('请输入6位验证码'))
  } else if (!reg.test(value)) {
    callback(new Error('请输入6位验证码'))
  } else {
    callback()
  }
}

// 营业执照验证
export const bzCodeValidate = (rule, value, callback) => {
  const reg = /^[0-9a-zA-Z]{18}$/;
  if (!value) {
    callback()
  } else if (!reg.test(value)) {
    callback(new Error('请输入正确格式的统一社会信用代码'))
  } else {
    callback()
  }
}